<template>
  <div>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Amazon Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'pre-alert' }" >
          {{ $t('Pre Alert') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Add Mawb') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'pre-alert' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>

    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- MAWB Details tab -->
      <tab-content
        :title="$t('MAWB Details')"
        :before-change="validationForm"
        class="ll-0"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t('Enter MAWB info') }}
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label=""
                label-for="MAWBNo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="MAWBNo"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('MAWB No') }}</label>
                  <b-form-input
                    id="MAWBNo"
                    v-model="form.mawb"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Please Enter MAWBNo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label=""
                label-for="customers"
              >
                <validation-provider
                  #default="{ errors }"
                  name="customers"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Customer') }}</label>
                  <b-form-input
                    id="customers"
                    v-model="form.customer"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Please Enter Customer"
                  />
                  <!-- <v-select
                    id="customers"
                    v-model="form.customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerList"
                    label="name"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label=""
                label-for="forwarder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Forwarder"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Forwarder') }}</label>
                  <b-form-input
                    id="forwarder"
                    v-model="form.forwarder"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Please Enter Forwarder"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label=""
                label-for="airLine"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Airline"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="airLine"
                    v-model="form.airLine"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Please Enter AirLine"
                  /> -->
                  <label class="ll-boldText">{{ $t('Airline') }}</label>
                  <v-select
                    id="airLine"
                    v-model="form.airLine"
                    :options="airLineList"
                    label="name"
                    @input="name => update(name)"
                    placeholder="Please Enter Airline"
                  >
                    <template #option="{ iata, name }">
                      <span class="ml-50 align-middle"> {{ name }}({{ iata}})</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label=""
                label-for="carTagNo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="carTagNo"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Car Tag No') }}</label>
                  <b-form-input
                    id="carTagNo"
                    v-model="form.carTagNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Please Enter carTagNo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Packages tab -->
      <tab-content
        :title="$t('Packages')"
        :before-change="validationFormInfo"
        class="ll-0"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0 ll-boldText">
                {{ $t('Enter Package TrackingID(TKID) here') }}
              </h5>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Package TrackingID"
                rules="required"
              >
                <b-form-textarea
                  id="ptID"
                  v-model="packageTrackingId"
                  :placeholder="$t('Please Enter Package TrackingID')"
                  rows="12"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Timestamps tab  -->
      <tab-content
        :title="$t('Timestamps')"
        :before-change="validationFormAddress"
        class="ll-0"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
        <!-- <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        > -->
          <b-row class="mb-2">
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t('Add a timestamp') }}
              </h5>
            </b-col>
            <b-col md="3">
              <span class="ll-boldText">{{ $t('Arrival Notice Time') }}</span>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Arrival Notice Time"
                rules="required"
              >
                <flat-pickr
                  v-model="form.arrivalNoticeTime"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-show="isEtdBox"
            class="mb-2"
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="3">
              <v-select
                :options="timesType"
                label="text"
                @input="name => update1(name, index)"
                ref="tType"
              >
              <!-- <template #option="{ name }">
                <span class="ml-50 align-middle"> {{ name }}</span>
              </template> -->
              </v-select>
            </b-col>
            <b-col md="4">
              <flat-pickr
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                value= ''
                @input="update2"
              />
            </b-col>
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>{{ $t('Delete') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-button
            v-show="isShowAdd"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('Add New') }}</span>
          </b-button>
          <!-- </b-form> -->
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import flatPickr from 'vue-flatpickr-component'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  // BForm,
  // BFormInvalidFeedback,
  BBreadcrumb,
  BBreadcrumbItem,BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    flatPickr,
    BButton,
    // BForm,
    // BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      airLine: '',
      form: {
        mawb: '',
        customer: 'Amazon US',
        forwarder: '',
        airLine: '',
        carTagNo: '',
        tkIdList: [],
        arrivalNoticeTime: '',
        // airTime: '',
        etdTime: '',
        etaTime: '',
        ataTime: '',
        shipmentPickupTime: '',
        arrivalScTime: '',
      },
      packageTrackingId: '',
      customerList: [],
      airLineList: [],
      timesType: ['ETD', 'ETA', 'ATA', 'Airport pickup', 'Arrival SC'],
      timesList: {
        etdTime: '',
        etaTime: '',
        ataTime: '',
        apuTime: '',
        ascTime: '',
      },
      times1: '',
      times2: '',
      isEtdBox: false,
      isShowAdd: true,
      city: '',
      required,
      email,
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      airlineRaw: {
        page: '1',
        size: 200,
        orderBy: 'id',
        sequence: 'asc',
      },
    }
  },
  mounted() {
    this.initTrHeight()
    this.getAirLines()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    // this.getCustmer()
  },
  destroyed() {
    window.addEventListener('resize', this.initTrHeight)
  },
  methods: {
    update1(name) {
      this.times2 = name
      const dd = this.$refs.tType
      const arr = []
      dd.map(tt => {
        arr.push(tt.selectedValue[0])
        return tt
      })
      this.timesType = this.timesType.filter(x => !arr.includes(x))
    },
    update2(times3) {
      // console.log('times3', times3)
      switch (this.times2) {
        case 'ETD':
          this.timesList.etdTime = times3
          break
        case 'ETA':
          this.timesList.etaTime = times3
          break
        case 'ATA':
          this.timesList.ataTime = times3
          break
        case 'Airport pickup':
          this.timesList.apuTime = times3
          break
        default:
          this.timesList.ascTime = times3
          break
      }
    },
    // 获取客户名称列表
    getCustmer() {
      this.$http.get('/customers').then(res => {
        this.customerList = res.data.content
      })
    },
    update(name) {
      this.form.airLine = `${name.name}(${name.iata})`
      this.airLine = name.iata
    },
    // 获取航班列表
    getAirLines() {
      this.$http.post('/airline/search', this.airlineRaw).then(res => {
        this.airLineList = res.data.data.content
      })
    },
    // 显示ETD timestamps
    repeateAgain(index) {
      if (this.nextTodoId === 2) {
        this.isEtdBox = true
        this.nextTodoId = 3
      } else if (this.nextTodoId > 2) {
        console.log(index)
        // console.log('23', this.items)
        // this.times1 = ''
        // this.times2 = ''
        this.items.push({
          id: this.nextTodoId += this.nextTodoId,
        })
      }
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // add Mawb 提交
    formSubmitted() {
      // this.form.customers = this.form.customers.customerID
      this.form.airLine = this.airLine
      this.form.tkIdList = this.packageTrackingId.split(/[(\r\n)\r\n]+/)
      this.form.etdTime = this.timesList.etdTime
      this.form.etaTime = this.timesList.etaTime
      this.form.ataTime = this.timesList.ataTime
      this.form.shipmentPickupTime = this.timesList.apuTime
      this.form.arrivalScTime = this.timesList.ascTime
      this.$http.post('/prealert/createMawbs', this.form).then(res => {
        console.log(res)
        if (res.data.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Add Success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'pre-alert' })
        }
      }).catch(error => console.log(error))
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style scoped>
.ll-0{
  min-height: 300px;
}
</style>
<style>
[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn:hover{
  box-shadow: 0 8px 25px -8px #7367f0;
}
</style>
