var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"breadcrumb-top"},[_c('b-breadcrumb',{},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"16"}})],1),_c('b-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$t('Amazon Order'))+" ")]),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'pre-alert' }}},[_vm._v(" "+_vm._s(_vm.$t('Pre Alert'))+" ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.$t('Add Mawb'))+" ")])],1)],1),_c('b-button',{staticClass:"ll-cancel",attrs:{"variant":"flat-secondary","to":{ name: 'pre-alert' }}},[_c('b-img',{staticClass:"mr-1",attrs:{"src":require('@/assets/images/imgs/return.png'),"height":"auto"}})],1),_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{staticClass:"ll-0",attrs:{"title":_vm.$t('MAWB Details'),"before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Enter MAWB info'))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"MAWBNo"}},[_c('validation-provider',{attrs:{"name":"MAWBNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('MAWB No')))]),_c('b-form-input',{attrs:{"id":"MAWBNo","state":errors.length > 0 ? false:null,"placeholder":"Please Enter MAWBNo"},model:{value:(_vm.form.mawb),callback:function ($$v) {_vm.$set(_vm.form, "mawb", $$v)},expression:"form.mawb"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"customers"}},[_c('validation-provider',{attrs:{"name":"customers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Customer')))]),_c('b-form-input',{attrs:{"id":"customers","state":errors.length > 0 ? false:null,"placeholder":"Please Enter Customer"},model:{value:(_vm.form.customer),callback:function ($$v) {_vm.$set(_vm.form, "customer", $$v)},expression:"form.customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"forwarder"}},[_c('validation-provider',{attrs:{"name":"Forwarder","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Forwarder')))]),_c('b-form-input',{attrs:{"id":"forwarder","state":errors.length > 0 ? false:null,"placeholder":"Please Enter Forwarder"},model:{value:(_vm.form.forwarder),callback:function ($$v) {_vm.$set(_vm.form, "forwarder", $$v)},expression:"form.forwarder"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"airLine"}},[_c('validation-provider',{attrs:{"name":"Airline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Airline')))]),_c('v-select',{attrs:{"id":"airLine","options":_vm.airLineList,"label":"name","placeholder":"Please Enter Airline"},on:{"input":function (name) { return _vm.update(name); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var iata = ref.iata;
var name = ref.name;
return [_c('span',{staticClass:"ml-50 align-middle"},[_vm._v(" "+_vm._s(name)+"("+_vm._s(iata)+")")])]}}],null,true),model:{value:(_vm.form.airLine),callback:function ($$v) {_vm.$set(_vm.form, "airLine", $$v)},expression:"form.airLine"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"carTagNo"}},[_c('validation-provider',{attrs:{"name":"carTagNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Car Tag No')))]),_c('b-form-input',{attrs:{"id":"carTagNo","state":errors.length > 0 ? false:null,"placeholder":"Please Enter carTagNo"},model:{value:(_vm.form.carTagNo),callback:function ($$v) {_vm.$set(_vm.form, "carTagNo", $$v)},expression:"form.carTagNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{staticClass:"ll-0",attrs:{"title":_vm.$t('Packages'),"before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0 ll-boldText"},[_vm._v(" "+_vm._s(_vm.$t('Enter Package TrackingID(TKID) here'))+" ")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Package TrackingID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"ptID","placeholder":_vm.$t('Please Enter Package TrackingID'),"rows":"12"},model:{value:(_vm.packageTrackingId),callback:function ($$v) {_vm.packageTrackingId=$$v},expression:"packageTrackingId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('tab-content',{staticClass:"ll-0",attrs:{"title":_vm.$t('Timestamps'),"before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Add a timestamp'))+" ")])]),_c('b-col',{attrs:{"md":"3"}},[_c('span',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Arrival Notice Time')))])]),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Arrival Notice Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}},model:{value:(_vm.form.arrivalNoticeTime),callback:function ($$v) {_vm.$set(_vm.form, "arrivalNoticeTime", $$v)},expression:"form.arrivalNoticeTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._l((_vm.items),function(item,index){return _c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEtdBox),expression:"isEtdBox"}],key:item.id,ref:"row",refInFor:true,staticClass:"mb-2",attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"3"}},[_c('v-select',{ref:"tType",refInFor:true,attrs:{"options":_vm.timesType,"label":"text"},on:{"input":function (name) { return _vm.update1(name, index); }}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'},"value":""},on:{"input":_vm.update2}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])],1)],1)],1)}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowAdd),expression:"isShowAdd"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Add New')))])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }